import type { RouteLocationAsPathGeneric } from "vue-router";

/**
 * This is basically the original sanctum auth middleware, but we remember the intended url the user wanted to visit.
 * This is not the same as the `redirect.keepRequestedRoute` option, because we need to remember the url in local storage,
 * so we can redirect the user after the 2fa or validation process.
 */
export default defineNuxtRouteMiddleware((to) => {
    const options = useSanctumConfig();
    const { isAuthenticated } = useSanctumAuth();
    const { setUrl } = useAfterLoginUrl();

    if (isAuthenticated.value) {
        return;
    }

    setUrl(to.fullPath);

    const endpoint = options.redirect.onAuthOnly;
    // @ts-expect-error nuxt-auth-sanctum type error
    const redirect: RouteLocationAsPathGeneric = { path: endpoint };
    return navigateTo(redirect, { replace: true });
});
